<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>基础能量消耗（BEE）
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="onSubmit">
            <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
               <van-field
                v-model="xingbie"
                :rules="[{ required: true, message: '请选择' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">性别 <i>*</i></span>
                  <van-radio-group v-model="xingbie" direction="horizontal">
                    <van-radio name="1">男</van-radio>
                    <van-radio name="2">女</van-radio>
                  </van-radio-group>
                  <!-- <span style="display: flex;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field v-model="username" placeholder="请输入" />
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>cm</span></div>
                  </span> -->
                </div>
              </template>
            </van-field>
               <van-field
                v-model="nianling"
                :rules="[{ required: true, message: '请输入' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">年龄 <i>*</i></span>
                  <!--                  <van-radio-group v-model="radio" direction="horizontal">-->
                  <!--                    <van-radio name="1">男</van-radio>-->
                  <!--                    <van-radio name="2">女</van-radio>-->
                  <!--                  </van-radio-group>-->
                  <span style="display: flex;">
                    <span class="information">
                      <van-cell-group class="vanCell" >
                        <van-field input-align="right" v-model="nianling" placeholder="请输入" />
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>岁</span></div>
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
                v-model="tizhong"
                :rules="[{ required: true, message: '请输入' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">体重 <i>*</i></span>
                  <span style="display: flex;align-items: center;">
                   <span class="information">
                     <van-cell-group class="vanCell">
                       <van-field input-align="right"  v-model="tizhong" placeholder="请输入" />
                     </van-cell-group>
                   </span>
                   <div class="unit"> <span>kg</span></div>
                 </span>
                </div>
              </template>
            </van-field>
            <van-field
                v-model="shengao"
                :rules="[{ required: true, message: '请输入' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">身高 <i>*</i></span>
                  <span style="display: flex;">
                   <span class="information">
                     <van-cell-group class="vanCell">
                       <van-field input-align="right"  v-model="shengao" placeholder="请输入" />
                     </van-cell-group>
                   </span>
                   <div class="unit"><span>cm</span></div>
                 </span>
                </div>
              </template>
            </van-field>
         
         
            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button class="formula-template-button" block type="info"  native-type="submit" style="width:90px">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults v-if="show" :countText="countText" :countTextList="countTextList"></CalculationResults>
      <!-- 后台设置的值 -->
      <div v-if="tableData.explain && tableData.explain.length > 0">
        <div v-for="(item,index) in tableData.explain" :key="index" class="StandardDescription">
          <div class="top_nav"><span></span>{{item.title}}</div>
          <div class="StandardDeatil">
            <span v-html="item.value">
            </span>
          </div>
        </div>
      </div>
      <!-- 参考文献 -->
      <div style="margin-bottom:50px;" v-if="tableData.references && tableData.references.length > 0">
        <div class="StandardDescription">
          <div class="top_nav"><span></span>参考文献</div>
          <div class="StandardDeatil2">
            <!-- <span v-html="item.value">
            </span> -->
            <span v-for="(item,index) in tableData.references" :key="item.title" >
            <div v-if="index !== 0" style="margin: 0 -10px 10px -10px;border-top: 1px solid #E7E7E7"></div>
              <div v-html="index+ 1 + '、' + item.title" class="references" @click="openLink(item.link)">
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      countTextList:[],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '基础能量消耗（BEE）',
      memberId: '1111',
      selectValue: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
    }
  },
  components: {
    FormulaBanner,
    MyPatient,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },
  watch:{
    "shengao":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "tizhong":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "nianling":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "xingbie":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },

  },
  methods:{
    coumputTetxt () {
      let gongshi = ''
      // let yingji = 0
      let jisuan = ''
      if (this.xingbie == 1) { //男
        jisuan = 66.47 + (13.75 * this.tizhong) + (5.0033 * this.shengao) - (6.775 * this.nianling)
        jisuan = jisuan.toFixed(1)
        gongshi = `${jisuan}kcal/d`
      } else { //女
        jisuan = 655.1 + (9.563 * this.tizhong) + (1.850 * this.shengao) - (4.676 * this.nianling)
        jisuan = jisuan.toFixed(1)
        gongshi = `${jisuan}kcal/d`
      }
      // this.yingjizhishuzhi = yingji
      this.result = jisuan
      this.countText = gongshi
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'DTZZDQBS',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          // this.tableData = res.data
          // this.formulaCode = res.data.code
          this.tizhong = res.data.content.tizhong.value
          this.shengao = res.data.content.shengao.value
          this.nianling = res.data.content.nianling.value
          this.xingbie = res.data.content.xingbie.value === '男' ? 1 : 2
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.coumputTetxt()
        }
        this.$nextTick(() => {
          this.show=true
        })
        // this.compute = false
      }
    },
    async submit () {
      this.coumputTetxt()
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
        data.result.DisplayResults = this.result+'kcal/d' 
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
        data.result.DisplayResults = this.result+'kcal/d'
      }
      this.show=true
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
